import React from "react";
import { Box } from "theme-ui";
import ProfileLayout from "profile/ProfileLayout";
import PersonalInformation from "profile/PersonalInformation";
import SEO from "components/SEO";

export default function Profile({}) {
  const BREADCRUMB_LINKS = [{ text: "My profile", to: "/profile" }];
  const PAGE_NAME = "Profile";

  return (
    <ProfileLayout page={PAGE_NAME} breadcrumbs={BREADCRUMB_LINKS}>
      <Box sx={{ display: ["none", null, "block"] }}>
        <PersonalInformation />
      </Box>
    </ProfileLayout>
  );
}

export const Head = () => <SEO title="Profile" />;
